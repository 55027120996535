import {Container, Grid, Typography, withStyles, WithStyles, withTheme, WithTheme} from '@material-ui/core';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {bindActionCreators} from 'redux';
import {doSessionChange} from '../actions/authActions';
import {appError} from '../actions/globalActions';
import LoggedAccountSwitch from '../components/LoggedAccountSwitch';
import LoginComponent from '../components/LoginComponent';
import {unWrapEtag} from '../lib/etagTools';
import {IReduxState, RootThunkDispatch} from '../reducers';
import {styles} from '../styles';

type InheritedProps = WithTranslation & RouteComponentProps & IPropsState & ActionList & WithTheme & WithStyles;

class SwitchView extends React.Component<InheritedProps> {
	constructor(props: InheritedProps) {
		super(props);
	}
	public componentDidMount() {
		this.props.appError(undefined);
	}
	public render() {
		const {t, application} = this.props;
		return (
			<Container maxWidth="sm">
				<Helmet>
					<title>{t('switch_account')}</title>
				</Helmet>
				<Grid item={true} xs={12}>
					<Typography>{application ? 'Login: ' + application.name : ''}</Typography>
				</Grid>
				<LoggedAccountSwitch />
				<LoginComponent />
			</Container>
		);
	}
}

// redux state props
const mapStateToProps = (state: IReduxState /*  ownProps: IOwnProps */) => ({
	application: unWrapEtag(state.app.currentApplication),
	isLoading: state.global.isLoading,
});
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
	bindActionCreators(
		{
			appError,
			doSessionChange,
		},
		dispatch,
	);
type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withTheme(withStyles(styles)(SwitchView))));
