import React, {useState} from 'react';

import {Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Translate from '@material-ui/icons/Translate';
import {useTranslation} from 'react-i18next';
import {useStyles} from '../styles';

interface ITopDrawer {
	open: boolean;
	onClose: () => void;
}

export default function TopDrawer(props: ITopDrawer) {
	const classes = useStyles();
	const {t, i18n} = useTranslation();
	const [state, setState] = useState({
		isLangOpen: false,
	});

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
			return;
		}
		setState({...state, isLangOpen: false});
		props.onClose();
	};
	const handleLanguage = (lang: string) => (event: React.KeyboardEvent | React.MouseEvent) => {
		i18n.changeLanguage(lang);
		toggleDrawer(false)(event);
	};
	const menuList = () => (
		<div className={classes.topMenuList} role="presentation" onKeyDown={toggleDrawer(false)}>
			<List>
				<ListItem button={true} key={'languages'} onClick={() => setState({...state, isLangOpen: !state.isLangOpen})}>
					<ListItemIcon>
						<Translate />
					</ListItemIcon>
					<ListItemText primary={t('languages')} />
					{state.isLangOpen ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={state.isLangOpen} timeout="auto" unmountOnExit={true}>
					<List component="div" disablePadding={true}>
						<ListItem className={classes.nestedMenuList} button={true} key={'lang_fi'} onClick={handleLanguage('fi-FI')}>
							<ListItemIcon>
								<Translate />
							</ListItemIcon>
							<ListItemText primary={t('fin')} />
						</ListItem>
						<ListItem className={classes.nestedMenuList} button={true} key={'lang_en'} onClick={handleLanguage('en-FI')}>
							<ListItemIcon>
								<Translate />
							</ListItemIcon>
							<ListItemText primary={t('eng')} />
						</ListItem>
						<ListItem className={classes.nestedMenuList} button={true} key={'lang_sv'} onClick={handleLanguage('sv-FI')}>
							<ListItemIcon>
								<Translate />
							</ListItemIcon>
							<ListItemText primary={t('sve')} />
						</ListItem>
					</List>
				</Collapse>
			</List>
		</div>
	);

	return (
		<Drawer open={props.open} onClose={toggleDrawer(false)}>
			{menuList()}
		</Drawer>
	);
}
