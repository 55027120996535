import React from 'react';

import {CircularProgress, Grid, Paper} from '@material-ui/core';
import {IApplicationPublic} from '../api/interfaces/application';
import {ApplicationLogo} from './ApplicationLogo';
interface IApplicationInfoProps {
	application: IApplicationPublic | undefined;
}
export const ApplicationComponent: React.FC<IApplicationInfoProps> = ({application}) => (
	<Grid alignContent="center" container={true} spacing={0}>
		<Grid item={true} xs={1} />
		<Grid item={true} xs={10}>
			<Paper>{application && application.logoUrl ? <ApplicationLogo image={application.logoUrl} /> : <CircularProgress />}</Paper>
		</Grid>
		<Grid item={true} xs={1} />
	</Grid>
);
