import {Action, Reducer} from 'redux';
import {ILoginParams} from '../lib/auth';
import {GlobalAction} from './globalReducer';

export interface IAuthSession {
	clientId: string;
	principal: string;
	accessToken: string;
	idToken: string | undefined;
	refreshToken: string | undefined;
}

/**
 * Redux action type keys
 */
export type Types =
	| 'auth/APP_TOKEN'
	| 'auth/ACCESS_TOKEN'
	| 'auth/ID_TOKEN'
	| 'auth/LOGIN_PARAMS'
	| 'auth/ADD_LOGIN_SESSION'
	| 'auth/REMOVE_LOGIN_SESSION'
	| 'auth/STORE_NONCE';

/**
 * Action interfaces
 */
interface IAuthApplicationAction extends Action<Types> {
	type: 'auth/APP_TOKEN';
	applicationToken: string | undefined;
}

interface IAuthAccountAction extends Action<Types> {
	type: 'auth/ACCESS_TOKEN';
	accessToken: string | undefined;
}

interface IAuthIdTokenAction extends Action<Types> {
	type: 'auth/ID_TOKEN';
	idToken: string | undefined;
}

interface IAuthLoginParamsAction extends Action<Types> {
	type: 'auth/LOGIN_PARAMS';
	loginParams: ILoginParams | undefined;
}

interface IAuthAddLoginSessionAction extends Action<Types> {
	type: 'auth/ADD_LOGIN_SESSION';
	session: IAuthSession;
}

interface IAuthRemoveLoginSessionAction extends Action<Types> {
	type: 'auth/REMOVE_LOGIN_SESSION';
	clientId: string;
	principal: string;
}
interface INonceAction extends Action<Types> {
	type: 'auth/STORE_NONCE';
	nonce: string | undefined;
}
export type AuthAction =
	| IAuthApplicationAction
	| IAuthAccountAction
	| IAuthIdTokenAction
	| IAuthLoginParamsAction
	| IAuthAddLoginSessionAction
	| IAuthRemoveLoginSessionAction
	| INonceAction;

/**
 * Redux state interface
 */
export interface IState {
	accessToken: string | undefined;
	applicationToken: string | undefined;
	idToken: string | undefined;
	loginParams: ILoginParams | undefined;
	sessions: IAuthSession[];
	currentSession: {[key: string]: IAuthSession};
	nonce: string | undefined;
}

/**
 * Initial redux state
 */
export const initialState: IState = {
	accessToken: undefined,
	applicationToken: undefined,
	currentSession: {},
	idToken: undefined,
	loginParams: undefined,
	sessions: [],
	nonce: undefined,
};

/**
 * Reducer
 */
export const reducer: Reducer<IState, AuthAction | GlobalAction> = (state = initialState, action): IState => {
	switch (action.type) {
		case 'auth/APP_TOKEN':
			return {
				...state,
				applicationToken: action.applicationToken,
			};
		case 'auth/ACCESS_TOKEN':
			return {
				...state,
				accessToken: action.accessToken,
			};
		case 'auth/ID_TOKEN':
			return {
				...state,
				idToken: action.idToken,
			};
		case 'auth/LOGIN_PARAMS':
			return {
				...state,
				loginParams: action.loginParams,
			};
		case 'auth/STORE_NONCE':
			return {
				...state,
				nonce: action.nonce,
			};
		case 'auth/ADD_LOGIN_SESSION':
			const sessions = state.sessions.filter((e) => e.principal !== action.session.principal || e.clientId !== action.session.clientId);
			const currentSession = {...state.currentSession};
			currentSession[action.session.clientId] = action.session;
			return {
				...state,
				currentSession,
				sessions: sessions.concat(action.session),
			};
		case 'auth/REMOVE_LOGIN_SESSION': {
			const currentSessionStack = {...state.currentSession};
			if (currentSessionStack[action.clientId]) {
				delete currentSessionStack[action.clientId];
			}
			return {
				...state,
				currentSession: currentSessionStack,
				sessions: state.sessions.filter((e) => e.principal !== action.principal || e.clientId !== action.clientId),
			};
		}
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
