import {Action, Reducer} from 'redux';

/**
 * Redux action type keys
 */
export type Types = 'global/ERROR' | 'global/LOADING' | 'global/RESET';

/**
 * Action interfaces
 */
interface IGlobalResetAction extends Action<Types> {
	type: 'global/RESET';
}

interface IGlobalLoadingAction extends Action<Types> {
	type: 'global/LOADING';
	isLoading: boolean;
}

interface IGlobalErrorAction extends Action<Types> {
	type: 'global/ERROR';
	error: string | undefined;
}

export type GlobalAction = IGlobalResetAction | IGlobalLoadingAction | IGlobalErrorAction;

/**
 * Redux state interface
 */
export interface IState {
	error: string | undefined;
	isLoading: boolean;
}

/**
 * Initial redux state
 */
export const initialState: IState = {
	error: undefined,
	isLoading: false,
};

/**
 * Reducer
 */
export const reducer: Reducer<IState, GlobalAction> = (state = initialState, action): IState => {
	switch (action.type) {
		case 'global/LOADING':
			return {
				...state,
				isLoading: action.isLoading,
			};
		case 'global/ERROR':
			return {
				...state,
				error: action.error,
			};
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
