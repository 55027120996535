import {getFirst} from './arrayUtil';

const errorTypes = [
	'interaction_required',
	'login_required',
	'account_selection_required',
	'consent_required',
	'invalid_request_uri',
	'invalid_request_object',
	'request_not_supported',
	'request_uri_not_supported',
	'registration_not_supported',
] as const;
type ErrorType = typeof errorTypes[number];

export interface IOauthErrorResponse {
	error: string;
	state?: string;
	error_description?: string;
	error_uri?: string;
	[key: string]: any;
}

export const isOauthError = (err: Error): err is OauthError => {
	return err.name === 'OauthError';
};

export class OauthError extends Error {
	public static buildErrorObject = (err: Error | OauthError, errorType: ErrorType, state: undefined | string | string[]): IOauthErrorResponse => {
		if (err instanceof OauthError) {
			return err.getErrorObject();
		} else {
			let outState = 'no_state';
			if (state) {
				outState = getFirst(state);
			}
			return {
				error: errorType,
				error_description: err.message,
				state: outState,
			};
		}
	};
	private errorType: ErrorType;
	private state: string | undefined;
	private description: string | undefined;
	private detailUri: string | undefined;
	constructor(errorType: ErrorType, state?: string, description?: string, detailUri?: string) {
		super(description || errorType);
		this.name = 'OauthError';
		this.errorType = errorType;
		this.state = state || undefined;
		this.description = description;
		this.detailUri = detailUri;
		// Set the prototype explicitly.
		Object.setPrototypeOf(this, OauthError.prototype);
		// attach stack
		if (typeof Error.captureStackTrace === 'function') {
			// tslint:disable-line:strict-type-predicates
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = new Error(description).stack;
		}
	}
	public getErrorObject(): IOauthErrorResponse {
		const out: IOauthErrorResponse = {
			error: this.errorType,
			state: this.state,
		};
		if (this.description) {
			out.error_description = this.description;
		}
		if (this.detailUri) {
			out.error_uri = this.detailUri;
		}
		return out;
	}
}
