import {Paper} from '@material-ui/core';
import React from 'react';
interface IApplicationInfoProps {
	image: string;
}
export const ApplicationLogo: React.FC<IApplicationInfoProps> = ({image}) => (
	<Paper>
		<img src={image} style={{width: '100%', padding: '1em'}} />
	</Paper>
);
