import {appLoading} from '../actions/globalActions';
import {getStore} from '../configureStore';
import {HttpClientClass} from './HttpClientClass';

// this hooks global loading state updates from HttpClient
const store = getStore();
const client = HttpClientClass.getInstance();
client.onLoading((isLoading) => {
	store.dispatch(appLoading(isLoading));
});
export const httpFetch = client.fetch;
