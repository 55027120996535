import {httpFetch} from './httpFetch';

interface IConfig {
	api_base: string;
	client_id: string;
	issuer: string;
	rel: string;
	branch: string;
}

let currentConfig: undefined | IConfig;
const getConfig = async () => {
	if (currentConfig) {
		return currentConfig;
	}
	if (process.env.NODE_ENV === 'test') {
		currentConfig = {
			api_base: 'http://localhost:8964/api',
			branch: 'undefined',
			client_id: '178fe972-16d0-4eea-951d-ce23860d1312',
			issuer: 'http://localhost:8964',
			rel: 'undefined',
		};
	} else {
		const res = await httpFetch('./config.json');
		currentConfig = (await res.json()) as IConfig;
		console.debug('config', currentConfig.branch, currentConfig.rel);
	}
	return currentConfig;
};

let currentApiBase: undefined | string;
export const getApiBase = async (): Promise<string> => {
	if (currentApiBase) {
		return currentApiBase;
	}
	if (process.env.REACT_APP_API_BASE) {
		currentApiBase = process.env.REACT_APP_API_BASE;
	} else {
		const {api_base} = await getConfig();
		currentApiBase = api_base;
	}
	return currentApiBase;
};

let currentClientId: undefined | string;
export const getClientId = async (): Promise<string> => {
	if (currentClientId) {
		return currentClientId;
	}
	if (process.env.REACT_APP_CLIENT_ID) {
		currentClientId = process.env.REACT_APP_CLIENT_ID;
	} else {
		const {client_id} = await getConfig();
		currentClientId = client_id;
	}
	return currentClientId;
};

let currentIssuer: undefined | string;
export const getIssuer = async (): Promise<string> => {
	if (currentIssuer) {
		return currentIssuer;
	}
	if (process.env.REACT_APP_ISSUER) {
		currentIssuer = process.env.REACT_APP_ISSUER;
	} else {
		const {issuer} = await getConfig();
		currentIssuer = issuer;
	}
	return currentIssuer;
};
