import {GlobalAction} from '../reducers/globalReducer';

// dispatcher actions
export const doRestAction = (): GlobalAction => {
	return {type: 'global/RESET'};
};

export const appLoading = (isLoading: boolean): GlobalAction => {
	return {type: 'global/LOADING', isLoading};
};

export const appError = (error: string | undefined): GlobalAction => {
	return {type: 'global/ERROR', error};
};
