import {TypedUseSelectorHook, useSelector as useReduxSelector} from 'react-redux';
import {Action, combineReducers} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import * as app from './appReducer';
import * as auth from './authReducer';
import * as global from './globalReducer';

/**
 * Helps to navigate redux state structure
 */
export interface IReduxState {
	app: app.IState;
	auth: auth.IState;
	global: global.IState;
}

/**
 * Combine all initial states
 * @see {@link createStore/default}
 */
export const initialState: IReduxState = {
	app: app.initialState,
	auth: auth.initialState,
	global: global.initialState,
};

/**
 * Combine all reducers with names
 */
export const rootReducer = combineReducers<IReduxState>({
	app: app.reducer,
	auth: auth.reducer,
	global: global.reducer,
});

/**
 * mapStateToProps "hook"
 * @example
 * const data = useSelector((state) => ({
 *   qwe: state.demo.todo,
 * }));
 */
export const useSelector: TypedUseSelectorHook<IReduxState> = useReduxSelector;

export type ThunkResult<R> = ThunkAction<R, IReduxState, undefined, Action>;

export type RootThunkDispatch = ThunkDispatch<IReduxState, undefined, Action>;

/**
 * Array updater
 * @param {T[]} dataArray source data array
 * @param {T} current update/add object
 * @param {(data: T) => boolean} callback comparison callback
 */
export const arrayUpdater = <T extends object>(dataArray: T[], current: T, callback: (data: T) => boolean) => {
	const ret = dataArray.slice();
	const idx = dataArray.findIndex((data) => callback(data));
	if (idx === -1) {
		// add object
		ret.push(current);
	} else {
		// replace object
		ret[idx] = current;
	}
	return ret;
};

export const arrayRemove = <T extends object>(dataArray: T[], callback: (data: T) => boolean) => {
	let ret = dataArray;
	const idx = dataArray.findIndex((data) => callback(data));
	if (idx !== -1) {
		ret = dataArray.slice(); // clone as we need new instance
		ret.splice(idx, 1);
	}
	return ret;
};
