import {createStyles, makeStyles, Theme} from '@material-ui/core';

// tslint:disable: object-literal-sort-keys

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		rootContainer: {
			paddingTop: theme.spacing(10),
		},
		loginButton: {
			margin: theme.spacing(1),
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			flexGrow: 1,
		},
		textField: {
			marginBottom: theme.spacing(1),
		},
		topMenuList: {
			width: 250,
		},
		nestedMenuList: {
			paddingLeft: theme.spacing(4),
		},
	});

export const useStyles = makeStyles(styles);
