import {Location as HistoryLocation} from 'history';
import * as querystring from 'querystring';
import {Action} from 'redux';
import {getAuthParams, getLogoutParams} from '../lib/auth';
import {IReduxState, RootThunkDispatch, ThunkResult} from '../reducers';
import {fetchApplicationData, setApplicationAction} from './appActions';
import {appAuthLoginParamsAction, doWeHaveSession, logoutCurrentSession, logoutSession} from './authActions';
import {appError} from './globalActions';

export const doWeHaveOauthQuery = (location: Location, routerlocation: HistoryLocation): ThunkResult<Promise<Action | void>> => async (
	dispatch: RootThunkDispatch,
	getState: () => IReduxState,
) => {
	const {search} = location;
	if (!search && search.length < 2) {
		return Promise.resolve();
	}
	const queryParams = querystring.parse(search.replace(/^\?/, ''));
	if (location.pathname === '/logout') {
		const {
			auth: {sessions},
		} = getState();
		const logoutParams = getLogoutParams(queryParams);
		const session = sessions.find((s) => s.idToken === logoutParams.id_token_hint);
		if (session) {
			await dispatch(logoutSession(session));
		}
		if (logoutParams.post_logout_redirect_uri) {
			window.location.href = logoutParams.post_logout_redirect_uri;
		}
		return;
	}
	if ('client_id' in queryParams) {
		const params = getAuthParams(queryParams);
		// TODO: handle ui_locales <=  find one matching "fr-CA fr en"
		try {
			const {
				app: {currentApplication},
			} = getState();
			if (currentApplication && currentApplication.data.clientId !== params.client_id) {
				dispatch(setApplicationAction()); // we are switching app, clear old
			}
			await dispatch(fetchApplicationData(params.client_id));
			dispatch(appAuthLoginParamsAction(params));
			// TODO: store original params to redux
			// build logic to decide what to do it user have multiple accounts for this clientId
			//
			switch (params.prompt) {
				case undefined: {
					if (!(await dispatch(doWeHaveSession()))) {
						window.location.href = './#login';
					}
					break;
				}
				case 'login': {
					await dispatch(logoutCurrentSession());
					window.location.href = './#login';
					break;
				}
				case 'none': {
					// TODO: build UI less return
					// if (!(await dispatch(doWeHaveSession()))) {
					// 		return error to return payload
					// }
					break;
				}
				case 'consent': {
					// TODO: build consent UI
					break;
				}
				case 'select_account': {
					const {
						auth: {sessions},
					} = getState();
					const sessionsList = sessions.filter((s) => s.clientId === params.client_id);
					if (sessionsList.length > 0) {
						window.location.href = './#switch';
					} else {
						window.location.href = './#login';
					}
				}
			}
			return Promise.resolve();
		} catch (err) {
			dispatch(appError(err));
		}
	}
};
