import {Action, Reducer} from 'redux';
import {arrayUpdater} from '.';
import {IApplicationPublic} from '../api/interfaces/application';
import {IEtagObject, unWrapEtag} from '../lib/etagTools';
import {GlobalAction} from './globalReducer';

/**
 * Redux action type keys
 */
export type Types = 'app/SET';

/**
 * Action interfaces
 */
interface IApplicationSetAction extends Action<Types> {
	type: 'app/SET';
	application: IEtagObject<IApplicationPublic> | undefined;
}

export type AppAction = IApplicationSetAction;

/**
 * Redux state interface
 */
export interface IState {
	currentApplication: IEtagObject<IApplicationPublic> | undefined;
	applications: IEtagObject<IApplicationPublic>[];
}

/**
 * Initial redux state
 */
export const initialState: IState = {
	applications: [],
	currentApplication: undefined,
};

/**
 * Reducer
 */
export const reducer: Reducer<IState, AppAction | GlobalAction> = (state = initialState, action): IState => {
	switch (action.type) {
		case 'app/SET':
			const currentApplication = unWrapEtag(action.application);
			if (action.application) {
				return {
					...state,
					applications: arrayUpdater(
						state.applications,
						action.application,
						(app) => currentApplication !== undefined && app.data.clientId === currentApplication.clientId,
					),
					currentApplication: action.application,
				};
			} else {
				return {
					...state,
					currentApplication: action.application,
				};
			}
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
