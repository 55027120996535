import {withStyles, WithStyles, withTheme, WithTheme} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import moment from 'moment';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {styles} from '../styles';

interface IProps {
	title: string;
	onMenuClick: () => void;
}

interface IState {
	lang: string;
	time: string;
}

type Props = IProps & WithTranslation & WithTheme & WithStyles;

class TopBar extends React.Component<Props, IState> {
	public static getDerivedStateFromProps(props: Props, state: IState) {
		if (props.i18n.language !== state.lang) {
			moment.locale(props.i18n.language);
			return {
				lang: props.i18n.language,
				time: moment().format('LT'),
			};
		}
		return null;
	}
	private timer: undefined | ReturnType<typeof setTimeout>;
	constructor(props: Props) {
		super(props);
		moment.locale(props.i18n.language);
		this.state = {
			lang: props.i18n.language,
			time: moment().format('LT'),
		};
		this.updateTime = this.updateTime.bind(this);
	}
	public componentDidMount() {
		this.timer = setInterval(this.updateTime, 1000);
	}
	public componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}
	public render() {
		const {title, classes} = this.props;
		const {time} = this.state;
		return (
			<div className={classes.root}>
				<AppBar>
					<Toolbar>
						<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => this.props.onMenuClick()}>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{title}
						</Typography>
						<Typography variant="h6">{time}</Typography>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
	private updateTime() {
		const time = moment().format('LT');
		if (this.state.time !== time) {
			this.setState({
				time,
			});
		}
	}
}
export default withTranslation()(withTheme(withStyles(styles)(TopBar)));

/* 
export default function TopBar(props: IProps) {
	const classes = useStyles();
	const {i18n} = useTranslation();
	moment.locale(i18n.language);
	const [state, setState] = useState({
		lang: i18n.language,
		time: moment().format('LT'),
	});
	const updateTime = () => {
		if (state.lang !== i18n.language) {
			moment.locale(i18n.language);
		}
		const time = moment().format('LT');
		if (time !== state.time) {
			console.log('update', time, state.time);
			setState({...state, time, lang: i18n.language});
		}
	};
	if (!timer) {
		timer = setInterval(updateTime, 1000);
	}
	return (
		<div className={classes.root}>
			<AppBar>
				<Toolbar>
					<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => props.onMenuClick()}>
						<MenuIcon />
					</IconButton>
					<Typography variant="h5" className={classes.title}>
						{props.title}
					</Typography>
					<Typography variant="h6">{state.time}</Typography>
				</Toolbar>
			</AppBar>
		</div>
	);
}
 */
