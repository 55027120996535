import {httpFetch} from './httpFetch';

interface IOpendIdConfig {
	issuer: string;
	jwks_uri: string;
	logout?: string;
	token_endpoint: string;
	userinfo_endpoint?: string;
	authorization_endpoint: string;
	end_session_endpoint?: string;
}

let currentConfig: undefined | IOpendIdConfig;
export const getOpenIdConfig = async (issuer: string) => {
	if (currentConfig) {
		return currentConfig;
	}
	const res = await httpFetch(issuer + '/.well-known/openid-configuration');
	currentConfig = (await res.json()) as IOpendIdConfig;
	return currentConfig;
};
