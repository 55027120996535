export const authResponseTypes = ['code', 'token', 'id_token', 'id_token token', 'code id_token', 'code token', 'code id_token token'] as const;
export type AuthResponseType = typeof authResponseTypes[number];

export const authResponseModes = ['fragment', 'query'] as const;
export type AuthResponseMode = typeof authResponseModes[number];

export const authPromptTypes = ['none', 'login', 'select_account', 'consent'] as const;

export type AuthPromptType = typeof authPromptTypes[number];

export interface IAuthRequest {
	client_id: string;
	response_type: AuthResponseType;
	response_mode: AuthResponseMode;
	redirect_uri: string;
	scope: string;
	nonce: string;
	state?: string;
	prompt?: AuthPromptType | undefined;
	login_hint?: string;
}
