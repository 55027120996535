import {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {doWeHaveOauthQuery} from '../actions/queryActions';
import {IReduxState, RootThunkDispatch} from '../reducers';

type Props = IPropsState & ActionList & RouteComponentProps;
class OAuthQuery extends Component<Props> {
	public componentDidMount() {
		console.log('OAuthQuery');
		this.props.doWeHaveOauthQuery(window.location, this.props.location);
	}
	public render() {
		return null;
	}
}

// redux state props
const mapStateToProps = (state: IReduxState) => {
	return {};
};
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
	bindActionCreators(
		{
			doWeHaveOauthQuery,
		},
		dispatch,
	);
type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OAuthQuery));
