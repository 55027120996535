/**
 * if value might be array, returns only first value
 * @param data value or array of values
 * @returns {T} if array returns first value else direct value
 */
export function getFirst<T = any>(data: T | T[], defaultValue?: undefined): T;
export function getFirst<T = any>(data: undefined | T | T[], defaultValue?: undefined): T | undefined;
export function getFirst<T = any>(data: undefined | T | T[], defaultValue: T): T;
export function getFirst<T = any>(data: undefined | T | T[], defaultValue: any): T {
	if (data === undefined) {
		return defaultValue || undefined;
	}
	if (Array.isArray(data)) {
		return data[0];
	}
	return data;
}

export const isValidConst = <T>(value: any, constArray: any): T => {
	if (constArray.indexOf(value) === -1) {
		throw new Error(value + ' is not valid const');
	}
	return value;
};
