import * as React from 'react';

import {createMuiTheme, CssBaseline, MuiThemeProvider, WithTheme, withTheme} from '@material-ui/core';
import red from '@material-ui/core/colors/red';

interface IProps {
	color?: string;
	secondary?: string;
	isDark?: boolean;
}

type Props = WithTheme & IProps;
class ThemeWrapper extends React.Component<Props> {
	public render() {
		const {theme, color, secondary, isDark} = this.props;
		return (
			<MuiThemeProvider
				theme={createMuiTheme({
					palette: {
						error: red,
						primary: {main: color ? color : theme.palette.primary.main},
						secondary: {main: secondary ? secondary : theme.palette.secondary.main},
						type: isDark ? 'dark' : 'light',
					},
				})}
			>
				<CssBaseline />
				{this.props.children}
			</MuiThemeProvider>
		);
	}
}
export default withTheme(ThemeWrapper);
