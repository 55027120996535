import {Container, Grid, LinearProgress, withStyles, WithStyles} from '@material-ui/core';
import React, {Component, FormEvent, Suspense} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {appLoading} from './actions/globalActions';
import {ApplicationComponent} from './components/ApplicationComponent';
import ErrorBoundary from './components/ErrorBoundary';
import OAuthQuery from './components/OAuthQuery';
import TopBar from './components/TopBar';
import TopDrawer from './components/TopDrawer';
import {IWithDarkMode, withDarkMode} from './DarkModeProvider';
import {unWrapEtag} from './lib/etagTools';
// import PrivateRoute from './components/PrivateRoute';
import {IReduxState, RootThunkDispatch} from './reducers';
import {IWithServiceWorker, withServiceWorker} from './ServiceWorkerProvider';
import {styles} from './styles';
import ThemeWrapper from './ThemeWrapper';
import ErrorView from './views/Error';
import SwitchView from './views/SwitchView';



// views code split
const Loading = () => <div>Loading!...</div>;
const HomeView = React.lazy(() => import('./views/Home' /* webpackChunkName: "home-view" */));
const LoginView = React.lazy(() => import('./views/LoginView' /* webpackChunkName: "login-view" */));
const BrokenView = React.lazy(() => import('./views/Broken' /* webpackChunkName: "broken-view" */));


const Home = () => (
	<Suspense fallback={<Loading />}>
		<HomeView />
	</Suspense>
);

const Login = () => (
	<Suspense fallback={<Loading />}>
		<LoginView />
	</Suspense>
);

const Broken = () => (
	<Suspense fallback={<Loading />}>
		<BrokenView />
	</Suspense>
);

interface IState {
	menuOpen: boolean;
}
type Props = WithTranslation & IPropsState & IWithServiceWorker & IWithDarkMode & WithStyles & ActionList;

class App extends Component<Props, IState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			menuOpen: false,
		};
		this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
		this.props.appLoading(false);
	}

	public render() {
		const {classes, error, isLoading, isDarkMode, application, idToken} = this.props;
		const {menuOpen} = this.state;
		return (
			<Router>
				<ThemeWrapper isDark={isDarkMode}>
					<Container maxWidth="sm" className={classes.rootContainer}>
						<ErrorBoundary onError={ErrorView}>
							<TopBar title={application ? application.name : ''} onMenuClick={() => this.setState({menuOpen: true})} />
							<TopDrawer open={menuOpen} onClose={() => this.setState({menuOpen: false})} />
							{isLoading ? (
								<Grid item={true} xs={12}>
									<LinearProgress />
								</Grid>
							) : null}
							<ApplicationComponent application={application} />
							{error ? <div>{error}</div> : null}
							<Switch>
								<Route exact={true} path="/" component={Home} />
								<Route exact={true} path="/login" component={Login} />
								<Route exact={true} path="/switch" component={SwitchView} />
								{/* <PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/secret" component={Secret} /> */}
								<Route exact={true} path="/broken" component={Broken} />
							</Switch>
							<OAuthQuery />
							{process.env.NODE_ENV === 'development' ? (
								<div>
									<button
										onClick={() =>
											(window.location.href =
												'/?response_type=id_token%20token&response_mode=fragment&client_id=5685a308-02cd-4c8f-931e-827967d2cce8&scope=openid&nonce=123&redirect_uri=http://localhost:8080/')
										}
									>
										Test App login!
									</button>
									<button
										onClick={() =>
											(window.location.href =
												'/?response_type=id_token%20token&response_mode=fragment&client_id=5685a308-02cd-4c8f-931e-827967d2cce8&prompt=login&scope=openid&nonce=123&redirect_uri=http://localhost:8080/')
										}
									>
										Test App login (prompt=login)!
									</button>
									<button
										onClick={() =>
											(window.location.href =
												'/?response_type=id_token%20token&response_mode=fragment&client_id=5685a308-02cd-4c8f-931e-827967d2cce8&prompt=select_account&scope=openid&nonce=123&redirect_uri=http://localhost:8080/')
										}
									>
										Test App login (prompt=select_account)!
									</button>
									<button
										disabled={idToken ? false : true}
										onClick={() => (window.location.href = `/logout?id_token_hint=${idToken}&post_logout_redirect_uri=http://localhost:8080/&state=123`)}
									>
										Logout
									</button>
								</div>
							) : null}
						</ErrorBoundary>
					</Container>
				</ThemeWrapper>
			</Router>
		);
	}
	private handleChangeLanguage(event: FormEvent<HTMLButtonElement>) {
		const target = event.currentTarget;
		this.props.i18n.changeLanguage(target.value);
	}
}

// redux state props
const mapStateToProps = (state: IReduxState) => {
	return {
		application: unWrapEtag(state.app.currentApplication),
		error: state.global.error,
		idToken:
			state.auth.loginParams && state.auth.currentSession[state.auth.loginParams.client_id]
				? state.auth.currentSession[state.auth.loginParams.client_id].idToken
				: undefined,
		isLoading: state.global.isLoading,
		isLoggedIn: state.auth.accessToken !== undefined,
	};
};
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) => bindActionCreators({appLoading}, dispatch);
type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(withServiceWorker(withDarkMode(App)))));
