import * as jwt from 'jsonwebtoken';
import {IJwtTokenBody} from '../api/interfaces/openId';

export const isValidToken = (token: string | undefined, issuer: string): token is string => {
	let out = false;
	if (token) {
		const now = Math.floor(Date.now() / 1000);
		const decode = jwt.decode(token) as IJwtTokenBody;
		if (decode && decode.exp && decode.exp > now && decode.iss && decode.iss === issuer) {
			out = true;
		} else {
			out = false;
		}
	}
	return out;
};
