import * as jwt from 'jsonwebtoken';
import * as querystring from 'querystring';
import {handleJsonResponse} from '.';
import {
	IOpenIdAccessTokenResponse,
	IOpenIdClientCredentialsGrant,
	IOpenIdIdTokenResponse,
	IOpenIdPasswordGrant,
	IOpenIdRefreshTokenGrant,
	IOpenIdTokenExchangeGrant,
} from '../api/interfaces/openId';
import {ILoginParams, makeNonce} from '../lib/auth';
import {getClientId, getIssuer} from '../lib/configTools';
import {httpFetch} from '../lib/httpFetch';
import {getOpenIdConfig} from '../lib/openIdConfigTools';
import {isValidToken} from '../lib/tokenValidation';
import {IReduxState, RootThunkDispatch, ThunkResult} from '../reducers';
import {AuthAction, IAuthSession} from '../reducers/authReducer';
import {appError} from './globalActions';

export interface IAuthResponse {
	access_token?: string;
	refresh_token?: string;
	id_token?: string;
	state?: string;
	[key: string]: any;
}

// dispatcher actions
const appAuthAppTokenAction = (applicationToken: string | undefined): AuthAction => {
	return {type: 'auth/APP_TOKEN', applicationToken};
};

export const appAuthIdTokenAction = (idToken: string | undefined): AuthAction => {
	return {type: 'auth/ID_TOKEN', idToken};
};

export const appAuthAccessTokenAction = (accessToken: string | undefined): AuthAction => {
	return {type: 'auth/ACCESS_TOKEN', accessToken};
};

export const appAuthLoginParamsAction = (loginParams: ILoginParams | undefined): AuthAction => {
	return {type: 'auth/LOGIN_PARAMS', loginParams};
};
export const appLogoutAction = (): AuthAction => {
	return {type: 'auth/ACCESS_TOKEN', accessToken: undefined};
};

export const addLoginSessionAction = (session: IAuthSession): AuthAction => {
	return {type: 'auth/ADD_LOGIN_SESSION', session};
};
export const removeLoginSessionAction = (clientId: string, principal: string): AuthAction => {
	return {type: 'auth/REMOVE_LOGIN_SESSION', clientId, principal};
};

export const storeNonceAction = (nonce: string | undefined): AuthAction => ({
	type: 'auth/STORE_NONCE',
	nonce,
});

export const createNonce = () => {
	return Math.random().toString(36).substr(2, 5);
};
export const handleRedirect = ({idToken, accessToken}: IAuthSession) => (dispatch: RootThunkDispatch, getState: () => IReduxState): string | undefined => {
	const {
		auth: {loginParams},
	} = getState();
	if (!loginParams) {
		dispatch(appError('fatal: no login params found!'));
		return;
	}
	const uriData: IAuthResponse = {};
	switch (loginParams.response_type) {
		case 'id_token': {
			uriData.id_token = idToken;
			break;
		}
		case 'id_token token': {
			uriData.id_token = idToken;
			uriData.access_token = accessToken;
			break;
		}
		default:
			dispatch(appError('not supported response_type: ' + loginParams.response_type));
			return;
	}
	if (loginParams.state) {
		uriData.state = loginParams.state;
	}
	if (idToken) {
		const {nonce} = jwt.decode(idToken) as {nonce?: string};
		if (!nonce) {
			console.error('no nonce found!');
			return;
		}
		if (nonce !== loginParams.nonce) {
			console.error('nonce dont match', nonce, loginParams.nonce);
			return;
		}
	}
	const redirectUrl = loginParams.redirect_uri + (loginParams.response_mode === 'fragment' ? '#' : '?') + querystring.stringify(uriData);
	if (process.env.NODE_ENV === 'test') {
		return redirectUrl;
	} else {
		window.location.href = redirectUrl;
		return;
	}
};

export const doLogin = (username: string, password: string): ThunkResult<Promise<void | string>> => async (
	dispatch: RootThunkDispatch,
	getState: () => IReduxState,
) => {
	dispatch(appError(undefined));
	const {
		auth: {loginParams},
	} = getState();
	if (!loginParams) {
		throw new Error('no login params found');
	}
	const {accessToken, refreshToken} = await dispatch(doPasswordLogin(loginParams.client_id, username, password, loginParams.nonce, loginParams.redirect_uri));
	const idToken = await dispatch(doIdTokenExchange(loginParams.client_id, accessToken, loginParams.nonce, loginParams.redirect_uri));
	const session: IAuthSession = {
		accessToken,
		clientId: loginParams.client_id,
		idToken,
		principal: username,
		refreshToken,
	};
	dispatch(addLoginSessionAction(session));
	return dispatch(handleRedirect(session));
};

const doPasswordLogin = (
	clientId: string,
	username: string,
	password: string,
	nonce: string,
	redirectUri: string,
): ThunkResult<Promise<{accessToken: string; refreshToken?: string}>> => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	try {
		const {
			auth: {loginParams},
		} = getState();
		if (!loginParams) {
			throw new Error('not login params');
		}
		let scope = loginParams.scope;
		if (!scope.includes('offline_access')) {
			scope += ' offline_access';
		}
		const {token_endpoint} = await getOpenIdConfig(await getIssuer());
		const appToken = await dispatch(getValidAppAuthToken());
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + appToken);
		const bodyObject: IOpenIdPasswordGrant = {
			client_id: clientId,
			grant_type: 'password',
			nonce,
			password,
			redirect_uri: redirectUri,
			scope,
			username,
		};
		const body = JSON.stringify(bodyObject);
		headers.set('Content-Type', 'application/json');
		headers.set('Content-Length', '' + body.length);
		const res = await httpFetch(token_endpoint, {
			body,
			headers,
			method: 'POST',
		});

		const data = await dispatch(handleJsonResponse<IOpenIdAccessTokenResponse>(res));
		if (!data) {
			throw new Error('empty response to get access token');
		}
		return {accessToken: data.access_token, refreshToken: data.refresh_token};
	} catch (err) {
		dispatch(appError(err.message));
		throw err;
	}
};

export const doAccessTokenRefresh = (clientId: string, refreshToken: string): ThunkResult<Promise<{accessToken: string; refreshToken?: string}>> => async (
	dispatch: RootThunkDispatch,
	getState: () => IReduxState,
) => {
	console.log('doAccessTokenRefresh');
	const {
		auth: {loginParams},
	} = getState();
	if (!loginParams) {
		throw new Error('not login params');
	}
	let scope = loginParams.scope;
	if (!scope.includes('offline_access')) {
		scope += ' offline_access';
	}
	const {token_endpoint} = await getOpenIdConfig(await getIssuer());
	const appToken = await dispatch(getValidAppAuthToken());
	const headers = new Headers();
	headers.set('Authorization', 'Bearer ' + appToken);
	const bodyObject: IOpenIdRefreshTokenGrant = {
		client_id: clientId,
		grant_type: 'refresh_token',
		nonce: loginParams.nonce,
		refresh_token: refreshToken,
		scope,
	};
	const body = JSON.stringify(bodyObject);
	headers.set('Content-Type', 'application/json');
	headers.set('Content-Length', '' + body.length);
	const res = await httpFetch(token_endpoint, {
		body,
		headers,
		method: 'POST',
	});
	const data = await dispatch(handleJsonResponse<IOpenIdAccessTokenResponse>(res));
	if (!data) {
		throw new Error('empty response to get access token');
	}
	return {accessToken: data.access_token, refreshToken: data.refresh_token};
};

export const doIdTokenExchange = (clientId: string, subjectToken: string, nonce: string, redirectUri: string): ThunkResult<Promise<string>> => async (
	dispatch: RootThunkDispatch,
	getState: () => IReduxState,
) => {
	console.log('doIdTokenExchange');
	const {
		auth: {loginParams},
	} = getState();
	if (!loginParams) {
		throw new Error('not login params');
	}
	const {token_endpoint} = await getOpenIdConfig(await getIssuer());
	const appToken = await dispatch(getValidAppAuthToken());
	const headers = new Headers();
	headers.set('Authorization', 'Bearer ' + appToken);
	const bodyObject: IOpenIdTokenExchangeGrant = {
		client_id: clientId,
		grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
		nonce,
		redirect_uri: redirectUri,
		requested_token_type: 'urn:ietf:params:oauth:token-type:id_token',
		scope: loginParams.scope,
		subject_token: subjectToken,
		subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
	};
	const body = JSON.stringify(bodyObject);
	headers.set('Content-Type', 'application/json');
	headers.set('Content-Length', '' + body.length);
	const res = await httpFetch(token_endpoint, {
		body,
		headers,
		method: 'POST',
	});
	try {
		const data = await dispatch(handleJsonResponse<IOpenIdIdTokenResponse>(res));
		if (!data) {
			throw new Error('empty response to get access token');
		}
		return data.id_token;
	} catch (err) {
		dispatch(appError(err.message));
		throw err;
	}
};

export const getValidAppAuthToken = (): ThunkResult<Promise<string>> => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	const issuer = await getIssuer();
	const {
		auth: {applicationToken},
	} = getState();
	if (isValidToken(applicationToken, issuer)) {
		return applicationToken;
	}
	const {token_endpoint} = await getOpenIdConfig(issuer);
	const headers = new Headers();
	const bodyObject: IOpenIdClientCredentialsGrant = {
		client_id: await getClientId(),
		grant_type: 'client_credentials',
		nonce: makeNonce(),
		scope: 'openid',
	};
	const body = JSON.stringify(bodyObject);
	headers.set('Content-Type', 'application/json');
	headers.set('Content-Length', '' + body.length);
	if (process.env.NODE_ENV === 'test') {
		headers.set('Origin', 'http://localhost:3000');
	}
	const res = await httpFetch(token_endpoint, {
		body,
		headers,
		method: 'POST',
	});
	const data = await dispatch(handleJsonResponse<IOpenIdAccessTokenResponse>(res));
	if (!data) {
		throw new Error('empty response to get application token');
	}
	dispatch(appAuthAppTokenAction(data.access_token));
	return data.access_token;
};

export const doLogoutWithSession = (token: string) => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	console.log('doLogoutWithSession');
	const issuer = await getIssuer();
	const {logout} = await getOpenIdConfig(issuer);
	if (logout) {
		const appToken = await dispatch(getValidAppAuthToken());
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + appToken);
		const bodyObject = {
			token,
		};
		const body = JSON.stringify(bodyObject);
		headers.set('Content-Type', 'application/json');
		headers.set('Content-Length', '' + body.length);
		if (process.env.NODE_ENV === 'test') {
			headers.set('Origin', 'http://localhost:3000');
		}
		const res = await httpFetch(logout, {
			body,
			headers,
			method: 'POST',
		});
		if (res.status !== 200) {
			throw new Error('logout http error' + res.status);
		}
	}
};

export const doWeHaveSession = (): ThunkResult<Promise<boolean>> => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	console.log('doWeHaveSession');
	const {
		auth: {currentSession, loginParams},
		app: {},
	} = getState();
	if (!loginParams) {
		console.log('session: no loginParams');
		return false;
	}
	const ses = currentSession[loginParams.client_id];
	if (ses && ses.refreshToken) {
		const {accessToken, refreshToken} = await dispatch(doAccessTokenRefresh(loginParams.client_id, ses.refreshToken));
		const idToken = await dispatch(doIdTokenExchange(loginParams.client_id, accessToken, loginParams.nonce, loginParams.redirect_uri));
		const newSession: IAuthSession = {
			accessToken,
			clientId: loginParams.client_id,
			idToken,
			principal: ses.principal,
			refreshToken,
		};
		dispatch(addLoginSessionAction(newSession));
		dispatch(handleRedirect(newSession));
		return true;
	}
	console.log('session: no current session found');
	return false;
};

export const doSessionChange = (session: IAuthSession) => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	dispatch(addLoginSessionAction(session));
	// retry with current session or if not ok then give login view
	if (!(await dispatch(doWeHaveSession()))) {
		window.location.href = './#login';
	}
};

export const logoutSession = (session: IAuthSession) => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	if (session.idToken) {
		await dispatch(doLogoutWithSession(session.idToken));
		dispatch(removeLoginSessionAction(session.clientId, session.principal));
	}
};

export const logoutCurrentSession = (): ThunkResult<Promise<void>> => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	const {
		auth: {currentSession, loginParams},
		app: {},
	} = getState();
	if (!loginParams) {
		console.log('session: no loginParams');
		return;
	}
	const ses = currentSession[loginParams.client_id];
	if (ses && ses.idToken) {
		await dispatch(logoutSession(ses));
	}
};
