import * as querystring from 'querystring';
import {AuthPromptType, authPromptTypes, AuthResponseMode, authResponseModes, AuthResponseType, authResponseTypes} from '../api/interfaces/authRequest';
import {getFirst, isValidConst} from '../lib/arrayUtil';
import {OauthError} from './oauthError';

export const makeNonce = () => Math.random().toString(36).substr(2, 5);

export const getRedirectUri = (location: Location) => {
	const {hostname} = location;
	switch (hostname) {
		case 'sahara-auth.firebaseapp.com':
			return `https://${hostname}/`;
		default:
			return 'http://localhost:3000/';
	}
};

export interface ILoginParams {
	client_id: string;
	response_type: AuthResponseType;
	redirect_uri: string;
	scope: string;
	response_mode: AuthResponseMode;
	state?: string;
	nonce: string;
	prompt: AuthPromptType | undefined;
	login_hint?: string;
	id_token_hint?: string;
}

const getAuthParam = (typeName: string, query: querystring.ParsedUrlQuery, validValues?: any) => {
	const currentState = getFirst(query.state);
	if (!(typeName in query)) {
		throw new OauthError('invalid_request_object', currentState, 'missing login parameter ' + typeName);
	}
	const value = getFirst(query[typeName]);
	if (!value) {
		throw new OauthError('invalid_request_object', currentState, 'missing login parameter ' + typeName);
	}
	if (validValues) {
		if (validValues.indexOf(value) === -1) {
			throw new OauthError('invalid_request_object', currentState, 'wrong value in ' + typeName);
		}
	}
	return value;
};

export const getAuthParams = (query: querystring.ParsedUrlQuery): ILoginParams => {
	const prompt = getFirst(query.prompt);
	const out: ILoginParams = {
		client_id: getAuthParam('client_id', query),
		id_token_hint: getFirst(query.id_token_hint),
		login_hint: getFirst(query.login_hint),
		nonce: getAuthParam('nonce', query),
		prompt: prompt ? isValidConst<AuthPromptType>(prompt, authPromptTypes) : undefined,
		redirect_uri: getAuthParam('redirect_uri', query),
		response_mode: isValidConst<AuthResponseMode>(getFirst(query.response_mode, 'query'), authResponseModes),
		response_type: getAuthParam('response_type', query, authResponseTypes) as AuthResponseType,
		scope: getAuthParam('scope', query),
		state: query.state ? getFirst(query.state) : undefined,
	};
	return out;
};

export const getLogoutParams = (query: querystring.ParsedUrlQuery) => {
	return {
		id_token_hint: getAuthParam('id_token_hint', query),
		post_logout_redirect_uri: query.post_logout_redirect_uri ? getAuthParam('post_logout_redirect_uri', query) : undefined,
		state: query.state ? getFirst(query.state) : undefined,
	};
};
