import {handleJsonResponse} from '.';
import {IApplicationPublic} from '../api/interfaces/application';
import {getApiBase} from '../lib/configTools';
import {getEtagHeader, IEtagObject, wrapEtag} from '../lib/etagTools';
import {httpFetch} from '../lib/httpFetch';
import {IReduxState, RootThunkDispatch, ThunkResult} from '../reducers';
import {AppAction} from '../reducers/appReducer';
import {appLogoutAction, getValidAppAuthToken} from './authActions';
import {appError} from './globalActions';

// dispatch actions
export const setApplicationAction = (application?: IEtagObject<IApplicationPublic> | undefined): AppAction => {
	return {type: 'app/SET', application};
};

export const fetchApplicationData = (clientId: string): ThunkResult<Promise<void>> => async (dispatch: RootThunkDispatch, getState: () => IReduxState) => {
	const {
		app: {currentApplication},
	} = getState();
	try {
		const appToken = await dispatch(getValidAppAuthToken());
		const body = JSON.stringify({client_id: clientId});
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + appToken);
		headers.set('Content-Type', 'application/json');
		headers.set('Content-Length', '' + body.length);
		if (currentApplication && currentApplication.etag && currentApplication.data.clientId === clientId) {
			headers.set('if-none-match', currentApplication.etag);
		}
		const res = await httpFetch((await getApiBase()) + '/app', {headers, body, method: 'POST'});
		const data = await dispatch(handleJsonResponse<IApplicationPublic>(res, appLogoutAction));
		if (data) {
			dispatch(setApplicationAction(wrapEtag(data, getEtagHeader(res))));
		}
	} catch (err) {
		dispatch(appError(err.message));
	}
};
