import {red} from '@material-ui/core/colors';
import {createMuiTheme} from '@material-ui/core/styles';
// tslint:disable: object-literal-sort-keys
// A custom theme for this app
const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#556cd6',
		},
		secondary: {
			main: '#19857b',
		},

		error: {
			main: red.A400,
		},
		background: {
			default: '#fff',
		},
	},
});

export default theme;
