import {
  Button,
  Grid,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { doSessionChange } from "../actions/authActions";
import { IReduxState, RootThunkDispatch } from "../reducers";
import { styles } from "../styles";

interface IState {
  idx: number | undefined;
}

type InheritedProps = IPropsState & ActionList & WithTheme & WithStyles;
// build initial state
const initialState = (props: InheritedProps): IState => {
  return {
    idx: undefined,
  };
};
class LoggedAccountSwitch extends React.Component<InheritedProps, IState> {
  constructor(props: InheritedProps) {
    super(props);
    this.state = initialState(props);
    this.handleSessionSwitch = this.handleSessionSwitch.bind(this);
  }
  public render() {
    const { accountList, isLoading } = this.props;
    return (
      <>
        {accountList.map((account, idx) => {
          return (
            <Grid
              key={account.clientId + "" + account.principal}
              item={true}
              xs={12}
            >
              <Button
                onClick={this.handleSessionSwitch}
                disabled={isLoading}
                value={idx}
                variant="contained"
                color="primary"
              >
                {account.principal}
              </Button>
            </Grid>
          );
        })}
      </>
    );
  }
  private handleSessionSwitch(e: React.MouseEvent<HTMLButtonElement>) {
    const target = e.currentTarget;
    const session = this.props.accountList[parseInt(target.value, 10)];
    if (session) {
      this.props.doSessionChange(session);
    }
  }
}

// redux state props
const mapStateToProps = (state: IReduxState /*  ownProps: IOwnProps */) => ({
  accountList: state.auth.sessions.filter(
    (s) => s.clientId === state.auth.loginParams?.client_id
  ),
  isLoading: state.global.isLoading,
});
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
  bindActionCreators(
    {
      doSessionChange,
    },
    dispatch
  );
type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(LoggedAccountSwitch)));
