import {Action} from 'redux';
import {RootThunkDispatch, ThunkResult} from '../reducers';

export const handleJsonResponse = <T>(res: Response, unAuthorizedAction?: () => Action): ThunkResult<Promise<T | undefined>> => async (
	dispatch: RootThunkDispatch,
) => {
	let payload: T | undefined;
	const contentType = res.headers.get('Content-Type');
	if (contentType && contentType.includes('application/json')) {
		payload = (await res.json()) as T;
	}
	switch (res.status) {
		case 200:
			return Promise.resolve(payload);
		case 304:
			return Promise.resolve(undefined);
		case 401: {
			if (dispatch && unAuthorizedAction) {
				dispatch(unAuthorizedAction());
				return Promise.resolve(undefined);
			}
			return Promise.reject(new Error(payload ? (payload as any).error_description : 'http error: ' + res.status));
		}
		default:
			return Promise.reject(new Error(payload ? (payload as any).error_description : 'http error: ' + res.status));
	}
};
