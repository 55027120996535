import {
	Box,
	Button,
	FormControl,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	TextField,
	withStyles,
	WithStyles,
	withTheme,
	WithTheme,
} from '@material-ui/core';
import LockOpen from '@material-ui/icons/LockOpen';
import VisibilityOn from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {bindActionCreators} from 'redux';
import {doLogin} from '../actions/authActions';
import {unWrapEtag} from '../lib/etagTools';
import {IReduxState, RootThunkDispatch} from '../reducers';
import {styles} from '../styles';

interface IState {
	password: string;
	username: string;
	isVisiblePassword: boolean;
}
/* interface IOwnProps {} */
type InheritedProps = WithTranslation & RouteComponentProps & IPropsState & ActionList & WithTheme & WithStyles;

// build initial state
const initialState = (props: InheritedProps): IState => {
	return {
		isVisiblePassword: false,
		password: '',
		username: '',
	};
};

class LoginComponent extends React.Component<InheritedProps, IState> {
	constructor(props: InheritedProps) {
		super(props);
		this.state = initialState(props);
		this.handleLogin = this.handleLogin.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
	}
	public render() {
		const {t, isLoading, classes} = this.props;
		const {isVisiblePassword, password, username} = this.state;
		return (
			<form className={classes.container} noValidate={true}>
				<Grid container={true} spacing={0}>
					<Grid item={true} xs={12}>
						<FormControl className={classes.textField} fullWidth={true}>
							<TextField
								required={true}
								name="username"
								label={t('username')}
								autoComplete="username"
								value={username}
								disabled={isLoading}
								onChange={this.onChange}
								margin="normal"
							/>
						</FormControl>
					</Grid>
					<Grid item={true} xs={12}>
						<FormControl className={classes.textField} fullWidth={true}>
							<InputLabel required={true} htmlFor="password">
								{t('password')}
							</InputLabel>
							<Input
								id="password"
								name="password"
								type={isVisiblePassword ? 'text' : 'password'}
								value={password}
								disabled={isLoading}
								onChange={this.onChange}
								onKeyUp={this.onKeyUp}
								autoComplete="current-password"
								endAdornment={
									(
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => this.setState({isVisiblePassword: !isVisiblePassword})}
												onMouseDown={(e) => e.preventDefault()}
											>
												{isVisiblePassword ? <VisibilityOn /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									)
								}
							/>
						</FormControl>
					</Grid>
					<Grid item={true} xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Button variant="contained" color="primary" className={classes.loginButton} disabled={isLoading} onClick={this.handleLogin}>
								{t('login')}
								<LockOpen className={classes.rightIcon} />
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>
		);
	}
	private async handleLogin() {
		try {
			if (this.props.application) {
				await this.props.doLogin(this.state.username, this.state.password);
				this.props.history.push('/');
			}
		} catch (err) {
			// ignore
		}
	}
	private onChange(e: React.FormEvent<HTMLInputElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
		const target = e.target as HTMLInputElement;
		switch (target.name) {
			case 'username':
				return this.setState({username: target.value});
			case 'password':
				return this.setState({password: target.value});
		}
	}
	private onKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.keyCode === 13) {
			e.preventDefault();
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			// this.handleLogin();
		}
		return false;
	}
}

// redux state props
const mapStateToProps = (state: IReduxState /*  ownProps: IOwnProps */) => ({
	application: unWrapEtag(state.app.currentApplication),
	isLoading: state.global.isLoading,
});
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
	bindActionCreators(
		{
			doLogin,
		},
		dispatch,
	);
type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withTheme(withStyles(styles)(LoginComponent)))) as unknown as React.ElementType<{}>;
